<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light nav-bar-hmf">
    <div class="container header-hmf">
      <div class="row w-100">
        <div class="col-4 my-auto">
          <div class="logo-area-hmf">
            <a href="#">
              <span class="hmf-main-desktop">
                <img src="images/logo-hasjrat-multifinance-desktop.jpg" class="img-fluid">
              </span>
              <span class="hmf-main-mobile">
                <img src="images/logo-hasjrat-multifinance-mobilejpg.jpg" class="img-fluid logo-mobile">
              </span>
            </a>
          </div>
        </div>
        <div class="col-8 text-right my-auto pad-header">
          <div class="menu-area-hmf my-auto">
            <span class="hmf-main-aduan-menu"><router-link to="dashboard">Dashboard</router-link></span>
            <span class="hmf-main-aduan-menu"><router-link to="data-aduan">Data Aduan</router-link></span>
            <span class="hmf-main-aduan-menu"><router-link to="data-penanganan">Data Penanganan</router-link></span>
            <span class="hmf-main-aduan-menu" v-if="!isoperator"><router-link to="data-user">Data Pengguna</router-link></span>
            <span class="hmf-main-aduan-menu"><router-link to="logout">Logout</router-link></span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "DashboardNavBar",
  data:()=>({
    isoperator: true
  }),
  mounted() {
    this.isoperator = this.$store.getters.isOperator;
  }
}
</script>

<style scoped>

</style>