import { createStore } from 'vuex'
const store = createStore({
    plugins:[],
    state(){
        return {
            count: 0,
            psbAllow : ['psb','psb-pindahan','psb-khusus','psb-pindahan-khusus'],
            tipepsb: 'psb',
            titlepsb:'',
            pindahan: false,
            email:'',
            formulirAgreement: false,
            loading: false,
            loggedIn: true,
            namapengguna:'',
            bearertoken:'',
            tahunajaran:'-----',
            operator: false
        }
    },
    getters:{
        getJudul(state){
            if(state.titlepsb.length>0){
                return "Pendaftaran Pengambilan Formulir Proses Penerimaan Siswa Baru (PSB) "+state.titlepsb+" SD Gemala Ananda Tahun Ajaran "+state.tahunajaran;
            }else {
                return "Pendaftaran Pengambilan Formulir Proses Penerimaan Siswa Baru (PSB) SD Gemala Ananda Tahun Ajaran "+state.tahunajaran;
            }
        },
        getNamaPengguna(state){
          return state.namapengguna;
        },
        getUserEmail(){
            const userData = localStorage.getItem('hmfpengaduan');
            if(userData){
                const objData = JSON.parse(userData);
                return objData.data.email;
            }else{
                return '';
            }
        },
        getUserInfo(){
            const userData = localStorage.getItem('hmfpengaduan');
            if(userData != null){
                if(userData){
                    const objData = JSON.parse(userData);
                    return objData;
                }else{
                    return null;
                }
            }else{
                return null;
            }
        },
        isOperator(state){
            const userData = localStorage.getItem('hmfpengaduan');
            if(userData != null){
                if(userData){
                    const objData = JSON.parse(userData);
                    state.operator = objData.data.userRole;
                }
            }
            if(state.operator===0){
                return true
            }else{
                return false
            }
        },
        isLoggedIn(state){
            const userData = localStorage.getItem('hmfpengaduan');
            if(userData != null){
                if(userData){
                    const objData = JSON.parse(userData);
                    state.email = objData.data.email;
                    state.bearertoken = objData.token;
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        getTahunAJaran(state){
            return state.tahunajaran;
        }
    },
    mutations:{
        increment(state){
            state.count++
        },
        changetitle(state,tipe){
            switch(tipe) {
                case 'psb':
                    state.titlepsb = ''
                    state.pindahan = false;
                    break;
                case 'psb-pindahan':
                    state.titlepsb = 'Pindahan';
                    state.pindahan = true;
                    break;
                case 'psb-khusus':
                    state.titlepsb = 'Khusus';
                    state.pindahan = false;
                    break;
                case 'psb-pindahan-khusus':
                    state.titlepsb = 'Pindahan Khusus';
                    state.pindahan = true;
                    break;
                default:
                    state.titlepsb = '-'
                    state.pindahan = false;
            }
        },
        tipepsb(state,tipe){
            if(state.psbAllow.includes(tipe)){
                state.tipepsb = tipe;
            }else{
                state.tipepsb = '';
            }
            store.commit('changetitle', state.tipepsb)
        },
        setFormulirAgreement(state,agree){
            state.formulirAgreement = agree;
        },
        loading(state,loading){
            state.loading = loading;
        },
        savesession(state,data){
            localStorage.setItem('hmfpengaduan', JSON.stringify(data));
            state.loggedIn = true;
        },
        setperiode(state,data){
            state.tahunajaran = data;
        },
        setoperator(state,data){
            state.operator = data;
        },
        deletesession(state){
            localStorage.removeItem('hmfpengaduan');
            state.loggedIn = false;
        },
        setLoggedIn(state,logged){
            state.loggedIn = logged;
        },
        setTahunAjaran(state,tahunajaran){
            state.tahunajaran = tahunajaran;
        }
    },
    actions: {

    }
})
export default store;