<template>
  <Toast></Toast>
  <Dialog v-model:visible="showmodal" :dismissableMask="true" @update:visible="toggleview" header="Tambah User" :style="{ width: '75vw' }" modal :contentStyle="{ height: 'auto' }">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title">Tambah User</h3>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-striped ">
            <tbody>
            <tr>
              <td>Name</td>
              <td><input type="text" class="p-inputtext p-component" v-model="formData.nama"></td>
            </tr>
            <tr>
              <td>Email</td>
              <td><input type="text" class="p-inputtext p-component" v-model="formData.email"></td>
            </tr>
            <tr>
              <td>Password</td>
              <td><input type="password" class="p-inputtext p-component" v-model="formData.password"></td>
            </tr>
            <tr>
              <td>Ulangi Password</td>
              <td><input type="password" class="p-inputtext p-component" v-model="formData.repassword"></td>
            </tr>
            <tr>
              <td>Jenis user</td>
              <td>
                <Dropdown v-model="formData.tipeuser" :options="tipeList" optionLabel="tipe" placeholder="Pilih tipe pengguna" class="w-full md:w-14rem">
                </Dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <button @click="submitAddUser" class="btn  btn-md btn-info" type="button" id="btn-req-status"><i
            class="fas fa-caret-right"></i> Tambah User
        </button>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import Dropdown from 'primevue/dropdown';
import DashboardServices from "@/services/dashboard.services";
import {required} from "@vuelidate/validators/dist/index";
import useValidate from "@vuelidate/core";
export default {
  name: "PageAddUser",
  components:{Toast,Dialog,Dropdown},
  props:{
    showform: Boolean
  },
  emits: ['toggle-view'],
  data:()=>({
    v$: useValidate(),
    showmodal: true,
    currentDataPenanganan:[],
    tipeList:[{"tipe":"Operator"},{"tipe":"Admin"}],
    formData:{
      nama:"",
      email:"",
      password:"",
      repassword:"",
      tipeuser:""
    }
  }),
  methods:{
    toggleview(){
      this.$emit('toggle-view',true)
      this.showmodal = false;
    },
    viewpagemodal(data){
      this.currentDataPenanganan = data;
      this.showmodalform = true;
    },
    showSuccess(msg,content) {
      this.$toast.add({ severity: 'success', summary: msg, detail: content, life: 3000 });
    },
    showError(msg,content) {
      this.$toast.add({ severity: 'error', summary: msg, detail: content, life: 3000 });
    },
    submitAddUser(){
      this.v$.formData.$validate();
      if(!this.v$.formData.$error){
        this.loading = true;
        let data = this.formData;
        let path = '/dashboard/crud-data-user';
        DashboardServices.postData(data,path).then(
            (res)=>{
              this.response = res.data;
              this.message = res.data.message;
              if(res.data.status===200){
                this.success = true;
                this.showSuccess(res.data.message,res.data.message);
              }else {
                this.showError(res.data.message,res.data.message);
                this.success = false;
              }
              this.loading = false;
            }
        );
      }else{
        this.loading = false;
        this.message = 'Mohon isi data dengan benar.';
      }
    }
  },
  validations() {
    return {
      formData:{
        nama:{required},
        email:{required},
        password:{required},
        repassword:{required},
        tipeuser:{required},
      }
    }
  },
  watch:{
    showform:{
      immediate: true,
      deep: false,
      handler(){
        this.showmodal = this.showform
      }
    },
  },
}
</script>

<style scoped>

</style>