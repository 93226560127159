<template>
  <PageInputAduanWeb></PageInputAduanWeb>
</template>

<script>
import PageInputAduanWeb from "@/components/pages/dataaduan/PageInputAduanWeb";
export default {
  name: "RouteEmbedPengaduan",
  components: {PageInputAduanWeb}
}
</script>

<style scoped>

</style>