<template>
  <Dialog v-model:visible="showmodal" :dismissableMask="true" @update:visible="toggleview" header="Data Penanganan Aduan Nasabah" :style="{ width: '75vw' }" modal :contentStyle="{ height: 'auto' }">
    <div class="modal-body">
      <div class="header-aduan-modal" style="padding-left: 15px;">
        <div class="row">
          <div class="col-md-4 header-aduan-col-modal"><strong>No Aduan :</strong> <br>{{data.no_pengaduan}}</div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Status :</strong> <br>{{data.status_pnganan}}</div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Tanggal Aduan:</strong> <br>{{data.tgl_pengaduan}}
          </div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Tanggal Penanganan:</strong> <br>{{data.tgl_penanganan}}
          </div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Ditangani oleh:</strong> <br>{{data.full_name}}
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-striped ">
          <thead>
          <tr>
            <th scope="col"><span class="header-col-aduan-hmf">Keterangan</span></th>
            <th scope="col"><span class="header-col-aduan-hmf">Data </span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>No Kontrak</td>
            <td>{{data.no_kontrak}}</td>
          </tr>
          <tr>
            <td>Nama Nasabah</td>
            <td>{{data.nasabah_nama}}</td>
          </tr>
          <tr>
            <td>Cabang</td>
            <td>{{data.nasabah_cabang}}</td>
          </tr>
          <tr>
            <td>Permasalahan</td>
            <td>{{data.permasalahan}}</td>
          </tr>
          <tr>
            <td>Hasil Penanganan</td>
            <td>{{data.penanganan}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <PageRiwayatPenanganan :data="dataRiwayatPenanganan"></PageRiwayatPenanganan>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import PageRiwayatPenanganan from "@/components/pages/datapenanganan/PageRiwayatPenanganan";
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "PageModalPenanganan",
  emits: ['toggle-view'],
  props:{
    data: undefined,
    showform: Boolean
  },
  components:{PageRiwayatPenanganan, Dialog},
  data:()=>({
    showmodal: true,
    loading: false,
    response:"",
    message:"",
    success: false,
    dataRiwayatPenanganan:[],
  }),
  methods:{
    toggleview(){
      this.$emit('toggle-view',true)
      this.showmodal = false;
    },
    getDataPenanganan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10,idpengaduan:this.data.id_pengaduan};
      let path = '/dashboard/load-data-penanganan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataRiwayatPenanganan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  watch:{
    showform:{
      immediate: true,
      deep: false,
      handler(){
        this.showmodal = this.showform;
        this.getDataPenanganan();
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>