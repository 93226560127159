<template>
  <button type="button" class="btn btn-info btn-sm mt-2 mb-2" @click="toggleaddriwayat">Tambah Riwayat</button>
  <PageAddRiwayat v-if="showaddriwayat" :idpengaduan="idpengaduan"></PageAddRiwayat>
  <div class="table-responsive">
    <table class="table table-striped ">
      <thead>
      <tr>
        <th scope="col"><span class="header-col-aduan-hmf">Riwayat Penanganan</span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
        <th scope="col"><span class="header-col-aduan-hmf"> </span></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><strong>Tanggal</strong></td>
        <td><strong>Status</strong></td>
        <td><strong>Keterangan</strong></td>
        <td><strong>File Pendukung</strong></td>
        <td><strong>Ditangani oleh</strong></td>
      </tr>
      <tr v-for="(item,idx) in data" :key="idx">
        {{setidpengaduan(item.id_pengaduan)}}
        <td>{{item.tgl_penanganan}}</td>
        <td>{{item.status_pnganan}}</td>
        <td>{{item.penanganan}}</td>
        <td>
          <button @click="viewimage(item)" type="button" class="btn btn-info btn-sm" data-toggle="modal"
                  data-target="#modalpenanganan">
            Lihat
          </button>
        </td>
        <td>{{item.full_name}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <Dialog v-model:visible="visible" :dismissableMask="true" modal header="Data lampiran" :style="{ width: '50%' }">
    <div class="card flex justify-center">
      <a :href=imagesrc alt="Download">Download</a>
<!--      <Image :src=imagesrc alt="Image" width="550" preview />-->
    </div>
  </Dialog>
</template>

<script>
import PageAddRiwayat from "@/components/pages/datapenanganan/PageAddRiwayat";
import Dialog from 'primevue/dialog';
import DashboardServices from "@/services/dashboard.services";
export default {
  name: "PageRiwayatPenanganan",
  components: {PageAddRiwayat,Dialog},
  props:{
    data: undefined
  },
  data:()=>({
    idpengaduan: "",
    showaddriwayat: false,
    visible: false,
    currentData:[],
    imagesrc:''
  }),
  computed:{
    getUserInfo(){
      let userinfo = this.$store.getters.getUserInfo;
      return userinfo.data.fullname;
    }
  },
  methods:{
    viewimage(data){
      let path = '/dashboard/image-view';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.imagesrc = response.data.data.image;
          }
      );
      this.visible =!this.visible
      this.currentData = data;
    },
    setidpengaduan(id){
      this.idpengaduan = id;
    },
    toggleaddriwayat(){
      this.showaddriwayat = !this.showaddriwayat;
    },
  }

}
</script>

<style scoped>

</style>