<template>
  <div class="row header-section-pengaduan">
    <div class="col-md-6">
      <div class="section-title">
        <h2 class="h2-hmf-section">Data User </h2>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <button @click="viewpagemodal" type="button" class="btn btn-warning btn-md" data-toggle="modal" data-target="#modalinputuser"><i
          class="fa fa-plus" aria-hidden="true"></i> Tambah User
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <DataTable v-model:filters="filters"
                   :value="dataUsers"
                   ref="dt"
                   tableStyle="min-width: 50rem"
                   :paginator="true"
                   :rows="30"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Tampil {first} dari {last} of {totalRecords} data pengaduan"
                   :first="0"
                   :loading="loading"
                   class="p-datatable-sm"
                   sortMode="single"
                   stripedRows>
          <template #empty> Data tidak ada. </template>
          <template #loading> Mengambil data. Mohon tunggu. </template>
          <Column field="full_name" header="Name" sortable style="min-width:16rem"></Column>
          <Column field="email_adr" header="Username" sortable style="min-width:16rem"></Column>
          <Column field="email_adr" header="Email" sortable style="min-width:16rem"></Column>
          <Column header="Detil" sortable style="min-width:16rem">
            <template #body="slotProps">
              <button @click="viewpagemodaldetail(slotProps.data)" type="button" class="btn btn-info btn-sm" data-toggle="modal"
                      data-target="#modalpenanganan">
                Lihat
              </button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <PageAddUser :showform="showmodalform" @toggle-view="togglemodal"></PageAddUser>
  <PageModalUser :showform="showdetail" :data="currentDataUsers" @toggle-view="togglemodalview"></PageModalUser>
</template>

<script>
import DashboardServices from "@/services/dashboard.services";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {FilterMatchMode} from "primevue/api";
import PageModalUser from "@/components/pages/part/PageModalUser";
import PageAddUser from "@/components/pages/datauser/PageAddUser";

export default {
  name: "PageListDataUser",
  components:{PageAddUser, PageModalUser, DataTable,Column},
  data:()=>({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    response:[],
    message:'',
    loading: false,
    success:false,
    dataUsers:[],
    currentDataUsers:[],
    showmodalform: false,
    showdetail: false
  }),
  methods:{
    showAddUsers(){

    },
    togglemodalview(){
      this.showdetail = false;
    },
    viewpagemodaldetail(data){
      this.currentDataUsers = data;
      this.showdetail = true;
    },
    togglemodal(){
      this.showmodalform = false;
    },
    viewpagemodal(data){
      this.currentDataUsers = data;
      this.showmodalform = true;
    },
    getDataUser(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10};
      let path = '/dashboard/load-data-user';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataUsers = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataUser();
  }
}
</script>

<style scoped>

</style>