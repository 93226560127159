<template>
  <!--ADUAN-->
  <div class="section-title" v-if="useheader">
    <h2 class="h2-hmf-section">Data pengaduan paling akhir</h2>
  </div>
  <div class="row header-section-pengaduan" v-if="usebutton">
    <div class="col-md-6">
      <div class="section-title">
        <h2 class="h2-hmf-section">Data pengaduan </h2>
      </div>
    </div>
    <div class="col-md-6 text-right" v-if="!viewform">
      <button type="button" @click="toggleForm" :class="classbutton" data-toggle="modal" data-target="#modalinputpengaduan">
        <i :class="classnya" aria-hidden="true"></i> Input Pengaduan
      </button>
      <button type="button" @click="toggleFormReport" :class="classbutton" class="ml-2">
        <i class="pi pi-file-export" aria-hidden="true"></i> Export
      </button>
    </div>
  </div>
  <Transition name="bounce">
    <div class="row" v-if="!viewform">
      <div class="col-12">
        <div class="table-responsive">
          <DataTable v-model:filters="filters"
                     :value="dataAduan"
                     ref="dt"
                     tableStyle="min-width: 50rem"
                     :paginator="true"
                     :rows="30"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Tampil {first} dari {last} of {totalRecords} data pengaduan"
                     :first="0"
                     :loading="loading"
                     class="p-datatable-sm"
                     stripedRows>
            <template #header v-if="!useheader">
              <div class="flex justify-content-end" >
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Pencarian" />
                </span>
              </div>
            </template>
            <template #empty> Data tidak ada. </template>
            <template #loading> Mengambil data. Mohon tunggu. </template>
            <Column header="No Pengaduan" sortable style="min-width:16rem">
              <template #body="slotProps">
                <a href="#" @click="viewpagemodal(slotProps.data)">
                  {{slotProps.data.no_pengaduan}}
                </a>
              </template>
            </Column>
            <Column field="tgl_pengaduan" header="Tanggal Pengaduan" sortable style="min-width:16rem"></Column>
            <Column field="full_name" header="Penerima" sortable style="min-width:16rem"></Column>
            <Column field="nasabah_nama" header="Nama Nasabah" sortable style="min-width:16rem"></Column>
            <Column field="no_kontrak" header="Nomor Kontrak" sortable style="min-width:16rem"></Column>
            <Column field="status_aduan" header="Status" sortable style="min-width:16rem"></Column>
            <Column header="Detil" style="min-width:16rem">
              <template #body="slotProps">
                <button @click="viewpagemodal(slotProps.data)" type="button" class="btn btn-info btn-sm" data-toggle="modal"
                        data-target="#modalpenanganan">
                  Lihat
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </Transition>
  <PageInputAduan v-if="viewform" @toggle-view="toggleForm"></PageInputAduan>
  <PageModalAduan :data="currentDataAduan" :showform="showmodalform" @toggle-view="togglemodal"></PageModalAduan>
  <PageModalExport :showform="showmodalreport" @toggle-view="togglemodalreport"></PageModalExport>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import PageInputAduan from "@/components/pages/dataaduan/PageInputAduan";
import DashboardServices from "@/services/dashboard.services";
import PageModalAduan from "@/components/pages/part/PageModalAduan";
import {FilterMatchMode} from "primevue/api";
import InputText from 'primevue/inputtext';
import 'primeicons/primeicons.css'
import PageModalExport from "@/components/pages/dataaduan/PageModalExport";
export default {
  name: "PageDataPengaduan",
  components: {PageModalExport, PageInputAduan,DataTable,Column, PageModalAduan,InputText},
  props:{
    useheader:Boolean,
    usebutton: Boolean
  },
  data:()=>({
    viewform: false,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    classnya: 'fa fa-plus',
    classbutton:'btn btn-success btn-md',
    response:[],
    message:'',
    loading: false,
    success:false,
    dataAduan:[],
    currentDataAduan:[],
    showmodalform: false,
    showmodalreport: false
  }),
  methods:{
    togglemodalreport(){
      this.showmodalreport = false;
    },
    togglemodal(){
      this.showmodalform = false;
      this.getDataPengaduan();
    },
    viewpagemodal(data){
      this.currentDataAduan = data;
      this.showmodalform = true;
    },
    toggleModal(){

    },
    toggleFormReport(){
      this.showmodalreport = true;
    },
    toggleForm(){
      this.viewform = !this.viewform;
      if(!this.viewform){
        this.classnya = 'fa fa-plus';
        this.classbutton = 'btn btn-success btn-md';
      }else{
        this.classnya = 'fa fa-minus';
        this.classbutton = 'btn btn-warning btn-md';
      }
    },
    showModal(){
      let element = this.$refs.modal.$el
      element.modal('show')
    },
    getDataPengaduan(){
      this.loading = true;
      this.$store.commit('loading',this.loading);
      let data = {limit:10};
      let path = '/dashboard/load-data-aduan';
      DashboardServices.postData(data,path).then(
          (response)=>{
            this.response = response.data;
            this.message = response.data.message;
            if(response.data.status===200){
              this.success = true;
              this.dataAduan = this.response.data;
            }else {
              this.success = false;
            }
            this.loading = false;
            this.$store.commit('loading',this.loading);
          }
      );
    }
  },
  mounted() {
    this.getDataPengaduan();
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  transform: translateX(20px);
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>