<template>
  <!--DATA MODAL-ADUAN-->
  <Dialog v-model:visible="showmodal" :dismissableMask="true" @update:visible="toggleview" header="Data Pengaduan Nasabah" :style="{ width: '75vw' }" modal :contentStyle="{ height: 'auto' }">
    <div class="modal-body">
      <div class="header-aduan-modal" style="padding-left: 15px;">
        <div class="row">
          <div class="col-md-4 header-aduan-col-modal"><strong>No Aduan :</strong> <br>{{data.no_pengaduan}}</div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Status :</strong> <br>{{data.status_aduan}}</div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Tanggal Aduan:</strong> <br>{{data.tgl_pengaduan}}
          </div>
          <div class="col-md-2 header-aduan-col-modal"><strong>Diterima oleh:</strong> <br>{{data.full_name}}
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped ">
          <thead>
          <tr>
            <th scope="col"><span class="header-col-aduan-hmf">Keterangan</span></th>
            <th scope="col"><span class="header-col-aduan-hmf">Data </span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>No Kontrak</td>
            <td>{{data.no_kontrak}}</td>
          </tr>
          <tr>
            <td>Nama Nasabah</td>
            <td>{{data.nasabah_nama}}</td>
          </tr>
          <tr>
            <td>Cabang</td>
            <td>{{data.nasabah_cabang}}</td>
          </tr>
          <tr>
            <td>Lampiran</td>
            <td>
              <button @click="visible = !visible" type="button" class="btn btn-info btn-sm" data-toggle="modal"
                      data-target="#modalpenanganan">
                Lihat
              </button>
            </td>
          </tr>
          <tr>
            <td>Permasalahan</td>
            <td>{{data.permasalahan}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button type="button" class="btn btn-info btn-sm mt-2 mb-2" @click="toggleaddriwayat">Tambah Riwayat</button>
    <PageAddRiwayat v-if="showaddriwayat" :idpengaduan="data.id_pengaduan"></PageAddRiwayat>
    <PageListRiwayat :datapengaduan="data"></PageListRiwayat>
  </Dialog>
  <Dialog v-model:visible="visible" :dismissableMask="true" modal header="Data lampiran" :style="{ width: '50%' }">
    <div class="card flex justify-center">
      <a :href=data.lampiran alt="Download">Download</a>
<!--      <Image :src=data.lampiran	alt="Image" width="550" preview />-->
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import PageListRiwayat from "@/components/pages/dataaduan/PageListRiwayat";
import PageAddRiwayat from "@/components/pages/datapenanganan/PageAddRiwayat";
export default {
  name: "PageModalAduan",
  emits: ['toggle-view'],
  props:{
    data: undefined,
    showform: Boolean
  },
  components:{PageListRiwayat, Dialog,PageAddRiwayat},
  data:()=>({
    visible: false,
    showmodal: true,
    showaddriwayat: false,
  }),
  methods:{
    toggleview(){
      this.$emit('toggle-view',true)
      this.showmodal = false;
    },
    toggleaddriwayat(){
      this.showaddriwayat = !this.showaddriwayat;
    }
  },
  watch:{
    showform:{
      immediate: true,
      deep: false,
      handler(){
        this.showmodal = this.showform
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>