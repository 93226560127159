<template>
  <Dialog v-model:visible="showmodal" :dismissableMask="true" @update:visible="toggleview" header="Data Pengaduan Nasabah" :style="{ width: '75vw' }" modal :contentStyle="{ height: 'auto' }">
    <table>
      <tr>
        <td>Nama</td>
        <td>:</td>
        <td>{{data.full_name}}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>:</td>
        <td>{{data.email_adr}}</td>
      </tr>
      <tr>
        <td>HP</td>
        <td>:</td>
        <td>{{data.mobile_phone}}</td>
      </tr>
    </table>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
export default {
  name: "PageModalUser",
  emits: ['toggle-view'],
  props:{
    data: undefined,
    showform: Boolean
  },
  components:{Dialog},
  data:()=>({
    showmodal: true,
    showaddriwayat: false,
  }),
  methods:{
    toggleview(){
      this.$emit('toggle-view',true)
      this.showmodal = false;
    },
    toggleaddriwayat(){
      this.showaddriwayat = !this.showaddriwayat;
    }
  },
  watch:{
    showform:{
      immediate: true,
      deep: false,
      handler(){
        this.showmodal = this.showform
      }
    },
  },
}
</script>

<style scoped>

</style>